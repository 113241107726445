import React from "react";
import FilteredMultiSelect from 'react-filtered-multiselect'

 const NewView = (props) => {
  

  const BOOTSTRAP_CLASSES = {
    filter: 'form-control',
    select: 'form-control',
    button: 'btn btn btn-block btn-default',
    buttonActive: 'btn btn btn-block btn-primary',
  }
    
    const handleDeselect = (index) => {
      const x = props.selectedOptions.slice()
      x.splice(index, 1)
      props.setSelectedOptions(x)
    }
    
    const handleClearSelection = (e) => {
      props.setSelectedOptions([])
    }
    const handleSelectionChange = (selectedOptions) => {
      selectedOptions.sort((a, b) => a.id - b.id)
      props.setSelectedOptions(selectedOptions)
    }

    return (
    <div className="container mb-5">
      <hr/><div className="row">
      <div className="col-md-5" id="mulselholder">
        <FilteredMultiSelect
          classNames={BOOTSTRAP_CLASSES}
          onChange={handleSelectionChange}
          options={props.allUsers}
          selectedOptions={props.selectedOptions}
          textProp="name"
          valueProp="id"
        />
        <p className="help-block">Press Enter when there's only one matching item to select it.</p>
      </div>
      <div className="col-md-5">
        {props.selectedOptions.length === 0 && <p>(nothing selected yet)</p>}
        {props.selectedOptions.length > 0 && <ol>
          {props.selectedOptions.map((user, i) => <li key={user.id}>
            {`${user.name} `}
            <span style={{cursor: 'pointer'}} onClick={() => handleDeselect(i)}>
              &times;
            </span>
          </li>)}
        </ol>}
        {props.selectedOptions.length > 0 && <button style={{marginLeft: 20}} className="btn btn-default" onClick={handleClearSelection}>
          Clear Selection
        </button>}
      </div>
    </div>
  </div>
    )
  }

  export default NewView