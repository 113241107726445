import React from 'react'
import './App.css'
import Navigation from './components/Nav/Nav'
import { StatusProvider, StatusContext } from './components/Context/StatusContext'
import Home from './components/Home/Home'


function App() {
  return (
    <StatusProvider>
      <Navigation />
      <Home />
    </StatusProvider>
  );
}
export default App;
