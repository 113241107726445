import Moment from "moment"

export const downloadAsCSV = (data, name="data") => {
  const toCSV = (data) => {
    const fields = Object.keys(data[0])
    const replacer = (key, value) => value === null ? '' : value
    const csv = data.map((row) => {
      return fields.map((fieldName) => {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    })
    csv.unshift(fields.join(',')) // add header column

    return csv.join('\r\n')
  }

  const downloadCSV = (csv) => {
    const hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = `${name}.csv`
    hiddenElement.click()
  }

  const csv = toCSV(data)
  downloadCSV(csv)
  return(csv)
}

export const requestAPI = (url, method="GET", data) => {
    const headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem("tattl-jwt")}`
    }
    const requestData = {
        method,
        headers
    }
    if (data) {
        requestData.body = JSON.stringify(data)
    }
    return fetch(url, requestData).then(r => r.json())
}

export const getTimeDif = (start, end) => {
  const startMoment = new Moment(start)
  const endMoment = new Moment(end)
  return new Moment.duration(endMoment.diff(startMoment)).asSeconds()
}


export const arrayUnique = (arr) => {
  return arr.filter((item, i)=>{
    return arr.indexOf(item) >= i
  })
}


export const updateUserMeta = async (user, key, value) => {
  if (user && key){
      const r = await requestAPI(`https://tempeproserve.com/tatl/api/wp-json/wp/v2/users/${user}`)
      const curentMeta = JSON.parse(r.meta.tatl)
      curentMeta[key] = value
      const m = await requestAPI(`https://tempeproserve.com/tatl/api/wp-json/wp/v2/users/${user}`, "POST", {meta: {tatl: JSON.stringify(curentMeta)}})
      return JSON.parse(m.meta.tatl)
  }
}

export const getUserMeta = async (user) => {
  if (user){
      const r = await requestAPI(`https://tempeproserve.com/tatl/api/wp-json/wp/v2/users/${user}`)
      let tatl
      try {
        tatl = JSON.parse(r.meta.tatl)
      } catch {
        tatl = r.meta.tatl
      }
      return tatl
  }
}