import React, { useState, useEffect } from "react";
import AnyChart from "anychart-react"

import { getTimeDif, arrayUnique } from "../../../functions"

const TicketLogTimeSpent = ({data, title}) => {

    const [displayed, setDisplayed] = useState()

    useEffect(() => {
        const allTimesAndStartStatuses = data.map(e=>[e.start_status, getTimeDif(e.start_time, e.end_time)])
        const allTicketTypes = arrayUnique(allTimesAndStartStatuses.map(e=>e[0]))
        window.test = {allTimesAndStartStatuses, allTicketTypes}
        const chartData = allTicketTypes.map((ticketType)=> [ticketType, allTimesAndStartStatuses.filter(e=>e[0]===ticketType).map((e)=>e[1]).reduce((a,b)=>a+b)])
        setDisplayed(chartData)
    }, [data])

    return (
        <AnyChart
        id="bargraph-ticket-lts"
        width={400}
        height={400}
        type="pie"
        data={displayed}
        title={title}
        />)
}
export default TicketLogTimeSpent