import React, { useEffect, useContext } from 'react'
import Dropdown from 'react-bootstrap/Dropdown'
import { StatusContext } from '../Context/StatusContext'
import { requestAPI } from "../../functions.js"

function StatusDropdown() {

    const {token, currentStatus, setCurrentStatus, statusList, setStatusList, isLoaded, setIsLoaded, setUserAuxLog, latestAuxLog, setLatestAuxLog, buttonToggle, setButtonToggle, setAdmin, setUserData} = useContext(StatusContext)

    const apiHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    
    useEffect(() => {
        fetchStatus()
        setButtonToggle('buttonDisabled')
        getUser().then(json => {
            if (json.role === "administrator") { setAdmin(true) }
            window.userId = json.id //returns 7
            window.user = json.display_name //returns "Gabriel Viveros-Goldammer"
            setUserData(json.display_name)
            getUserMeta().then(json => {
                let initialStatusData 
                try{
                    initialStatusData = JSON.parse(json.meta.tatl)
                } catch {
                    initialStatusData = {status: "Inactive", id: "13"}
                }
                setCurrentStatus(initialStatusData.status) //Correctly returns previous Aux Status (Break, Lunch etc.)
            })
        }).then(() => getAuxLog()) //Correctly returns only the logs by specific userId
    }, [])





    async function fetchStatus() {
        const fetchedStatus = await fetch("https://tempeproserve.com/tatl/api/wp-json/tracking/v1/read-aux-statuses", {
            method: 'GET',
            headers: apiHeader
        })

        fetchedStatus
            .json()
            .then(statusJson => {
                setStatusList(statusJson.data)
                setIsLoaded(true)
            })
            .catch(err => console.log(err))
    }

    async function getUser() {
        const fetchedUser = await fetch("https://tempeproserve.com/tatl/api/wp-json/tracking/v1/userinfo", {
            method: 'GET',
            headers: apiHeader
        })

        const jsonUser = await fetchedUser.json()
        return jsonUser
    }

    async function openAuxLog(statusId) {
        return await requestAPI(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/openaux?aux-number=${statusId}`)
            .then(json => {
                let returnedLogItem = json
                return returnedLogItem
            })
        // May not need to return anything for this, just run the function
    }

    async function closeAuxLog() {
        return await fetch(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/updateaux?id=${latestAuxLog ? latestAuxLog.id : 0}`, {
            method: 'GET',
            headers: apiHeader
        }).catch(() => true)
    }

    function getAuxLog() {
        return fetch(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/read-aux-log?user=${window.userId}`, {
            method: 'GET',
            headers: apiHeader
        }).then(response => response.json())
            .then(auxlogJson => {
                setUserAuxLog(auxlogJson.data) //Correctly returns all aux logs by userId
                setLatestAuxLog(auxlogJson.data[auxlogJson.data.length - 1]) //Correctly sets state of object with the latest log of user
                setButtonToggle('') //Sets class back to empty to allow people to click on the dropdown
            })
    }

    function getUserMeta() {
        return fetch(`https://tempeproserve.com/tatl/api/wp-json/wp/v2/users/${window.userId}`, {
            method: 'GET',
            headers: apiHeader
        }).then(response => response.json())
    }

    function updateUserMeta(currentAuxStatus, latestAuxLogId, { meta }) {

        let currentMeta = meta.tatl !== "" ? JSON.parse(meta.tatl) : {status: "Inactive", id: "13"}

        const newData = {
            ...currentMeta,
            status: currentAuxStatus,
            id: latestAuxLogId
        }

        return requestAPI(`https://tempeproserve.com/tatl/api/wp-json/wp/v2/users/${window.userId}`, "POST", { meta: { tatl: JSON.stringify(newData) } })
    }

    

    function handleClick(e, statusName, statusId) {
        e.preventDefault() //Prevents window from refreshing
        setButtonToggle('buttonDisabled') //Correctly disables button from being clicked again in quick succession
        setCurrentStatus(statusName)
        closeAuxLog().then(() => {
            openAuxLog(statusId)
                .then(() => {
                    getAuxLog()
                })
                .then(() => getUserMeta())
                .then((json) => updateUserMeta(statusName, statusId, json))
                // .then(() => window.location.reload()) //Remove this after refactoring the timer switch
        })
    }


    if (isLoaded) {
        return (
            <Dropdown> 
                <Dropdown.Toggle id="dropdown-basic" className={buttonToggle}>
                    {currentStatus === '' ? `Select Status` : currentStatus}
                </Dropdown.Toggle>

                <Dropdown.Menu alignRight>
                    {statusList.map && statusList.map((status) => { return <Dropdown.Item onClick={(e) => (handleClick(e, status.status_name, status.id))} href={status.status_name.toLocaleLowerCase()} key={status.id} id={status.status_name}>{status.status_name}</Dropdown.Item> })}
                </Dropdown.Menu>
            </Dropdown>
        )
    } else {
        return (
            <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    Loading Status
                </Dropdown.Toggle>
            </Dropdown>
        )
    }

}

export default StatusDropdown