import React, { useState, useContext, useEffect } from 'react'
// import Moment from 'react-moment'
import moment from 'moment'
import Timer from 'react-compound-timer'
import { StatusContext } from '../Context/StatusContext'
import { requestAPI } from "../../functions.js";


function StatusTimer() {
    const {currentStatus, userData} = useContext(StatusContext)

    const [currentTime, setCurrentTime] = useState(null)
    const [toggle, setToggle] = useState(!0)


    useEffect(() => {
        setTimeout(()=>{
            getCurrentTime().then(time => {
                setCurrentTime(time)
                setToggle(!1)
            })
        }, 2000)
    }, [currentStatus])


    useEffect(() => {
        setToggle(!0)
    }, [toggle])

    const convertIT = (time) => {
        const pieces = time.split(":")
        const seconds = Number(pieces[2])
        const min = Number(pieces[1])
        const hours = Number(pieces[0])
        const totalMin = (hours * 60) + min
        const totalSeconds = (totalMin * 60) + seconds
        let output = totalSeconds + "000"
        return Number(output)
    }

    async function getCurrentTime() {
        return requestAPI(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/showstatus`).then(json => {

            const myUserData = json.data.filter(user => user.display_name === userData)
            let elapsedTime = myUserData[0] ? myUserData[0].elapsed : "00:00:00"
            let convertedTime = convertIT(elapsedTime)
            return convertedTime
            // setCurrentTime(convertedTime)
        })
    }


    if (currentTime != null && toggle) {
        return (
            <Timer initialTime={currentTime} className={currentStatus}>
                {({ reset }) => (
                    <div>
                        <p><strong>Time in Status:</strong> <Timer.Hours formatValue={value => `${value}`.match(/\d\d/) ? value : `0${value}`} />:<Timer.Minutes formatValue={value => `${value}`.match(/\d\d/) ? value : `0${value}`} />:<Timer.Seconds formatValue={value => `${value}`.match(/\d\d/) ? value : `0${value}`} /></p>
                    </div>
                )}
            </Timer>
        )
    } else {
        return (
            <div></div>
        )
    }

}

export default StatusTimer