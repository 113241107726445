import React, { useState, useContext, useEffect } from "react";
import { Button } from "react-bootstrap";
import DataTable from 'react-data-table-component';

import { arrayUnique, requestAPI } from "../../functions"
import { StatusContext } from "../Context/StatusContext";
import ReportDropdown from "./ReportDropdown"
import ChartRenderer from "./ChartRenderer"

function Stats() {
  const {isAdmin, userData, hideAdmin, admins, views, view} = useContext(StatusContext)

  const [range, setRange] = useState(null);
  const [report, setReport] = useState(null);
  const [displayedData, setDD] = useState(null);
  const [filteredData, setFD] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [filters, setFilter] = useState("");
  const [tableView, setTableView] = useState(!0);
  const [dropdown, setDropdown] = useState(null);


  useEffect(() => {
    const isCurrentStatus = report ? !!report[1].match("current") : 0
    //window.test =  {isCurrentStatus, displayedData}
    const filtersArray = filters.split("|").map(e => e.trim())
    if (displayedData) {
      const userAccessLevelData = displayedData.filter((e, i) => isAdmin ? e : e.display_name === userData)
      const allMatches = filtersArray.map(each => {
        return isCurrentStatus ? displayedData : userAccessLevelData.filter(e => JSON.stringify(e).toLocaleLowerCase().match(each.toLowerCase()))
      })
      let filteredData = arrayUnique(allMatches.flat())
      if (hideAdmin && isAdmin) {
        filteredData = filteredData.filter(e=>{
          return !admins.includes(e.display_name)
        })
      }
      if (isAdmin && view !== 'Select View') {
        const filteredView = views.find(e=> e.viewName === view)
        const displayNames = filteredView.users.map(e=>e.name)
        filteredData = filteredData.filter(e=>{
          return displayNames.includes(e.display_name)
        })
      }
      setFD(filteredData)

      const sendUpdate = (id, table, newvalue) => {
        if (table === "start_date" || table === "start_time") {
          const rowdata = filteredData.find(e=>e.id == id)
          console.log(table)
          if (table === 'start_date'){
            table = "start_time"
            newvalue = `${newvalue} ${rowdata.start_time}`
          } else if (table === 'start_time'){
            newvalue = `${rowdata.start_date} ${newvalue}`
          }
          return requestAPI(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/correctaux?id=${id}&table=${table}&value=${newvalue}`)
        } else if (table === "end_date" || table === "end_time") {
          const rowdata = filteredData.find(e=>e.id == id)
          const oldValue = `${rowdata.end_date} ${rowdata.end_time}`
          console.log(table)
          if (table === 'end_date'){
            table = "end_time"
            newvalue = `${newvalue} ${rowdata.end_time}`
          } else if (table === 'end_time'){
            newvalue = `${rowdata.end_date} ${newvalue}`
          }
          return requestAPI(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/correctaux?id=${id}&table=${table}&value=${newvalue}`)
        } else {
          return requestAPI(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/correctaux?id=${id}&table=${table.replace("status_name","aux_number")}&value=${newvalue}`)
        }
      }

      const addListeners = () => {
        const renderedTables = document.querySelector(".rdt_TableBody")
        const headRow = document.querySelector(".rdt_TableHeadRow")
        const renderedRows = renderedTables ? renderedTables.children : []
        for (let i = 0; i < renderedRows.length; i++) {
          const renderedCols = renderedRows[i].children
          for (let j = 0; j < renderedCols.length; j++) {
            const rowsID = renderedRows[i].children[0].children[0].innerHTML
            const clickedValue = renderedCols[j].children[0].innerHTML
            const eachCol = renderedCols[j]
            let colKey = headRow.children[j].children[0].children[0].innerHTML
            eachCol.addEventListener("dblclick", () => {
              console.log(`I am ${clickedValue} under ${colKey}. my id is ${rowsID}`)
              const conversion = colKey === "status_name" ? `
1 = Lunch      |  2 = Break
3 = Posts      |  4 = Ops
5 = Training   |  6 = Huddle
7 = Tasks      |  8 = Helping MPC
9 = Audit      |  10 = 1-on-1
11 = Builds    |  12 = Log Out
13 = Inactive  |  14 = Tickets
              ` : ''
              const changedValue = prompt(`What would you like to change this to? ${conversion}`, clickedValue)
              if (changedValue && changedValue !== clickedValue) {
                console.log(`I am ${clickedValue} under ${colKey}. my id is ${rowsID}. I want to update my value to ${changedValue}`)
                sendUpdate(rowsID, colKey, changedValue)
                .then((r) => {if (r.success) {
                  console.log('updated')
                  window.test = filteredData
                  setDD(displayedData.map((e)=> {
                    if (rowsID == e.id) {
                      e[colKey] = changedValue
                      return e
                    } else {
                      return e
                    }
                  }))
                } else {
                  console.log('it diddnt work')
                }
              })
              }
            })
          }
        }
        //window.test = filteredData
      }
      if (isAdmin && tableView) {setTimeout(addListeners, 0)}

    }
  }, [displayedData, filters, tableView, hideAdmin, view])

  return (
    <div>
      <ReportDropdown data={{ range, setRange, report, setReport, fileName, setFileName, displayedData, setDD, filters, setFilter, filteredData, dropdown, setDropdown }} />
      {!!filteredData && !!Object.keys(displayedData).length && <div>
        <Button variant="outline-primary" className="ml-2" onClick={() => setTableView(!tableView)}>{tableView ? "Charts" : "Table"}</Button>
        {tableView ? (
          <DataTable
            pagination={true}
            paginationPerPage={100}
            title={<div>
              {`${filteredData.length} Results`}
            </div>}
            columns={Object.keys(displayedData[0]).map(e => {
              return {
                name: e,
                selector: e,
                sortable: true
              }
            })}
            data={filteredData}
          />
        ) : <ChartRenderer data={filteredData} title={dropdown} />}
      </div>}
    </div>
  )
}

export default Stats;
