import React, { useState, useEffect, useContext } from 'react'
import StatusDropdown from '../General/StatusDropdown';
import { StatusContext } from '../Context/StatusContext'
import { requestAPI } from "../../functions.js";
import useInterval from "../CustomHooks/useInterval"



function Navigation() {
    const [time, setTime] = useState(0);
    const { token, currentStatus, setCurrentStatus, statusList, setUserAuxLog, latestAuxLog, setLatestAuxLog, setButtonToggle, admins, setAdmins, setAllUsers, isAdmin} = useContext(StatusContext)
    const [user, setUser] = useState('')
    const [userId, setUserId] = useState('')
    const [currentInitialTime, setCurrentInitialTime] = useState('')

    const apiHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }

    useEffect(() => {
        getUser()
        requestAPI("https://tempeproserve.com/tatl/api/wp-json/wp/v2/users/?roles=administrator").then(r =>{
            if (r[1]) {
                setAdmins(r.map(e=>e.name))
            } else {
                setAdmins([])
            }
        })
            requestAPI(`https://tempeproserve.com/tatl/api/wp-json/wp/v2/users?per_page=99&roles=editor,administrator`).then(r => {
                setAllUsers(r)
            })
    }, [])

    useInterval(() => {
        checkStatus()
    }, 30000)



    async function checkStatus() {
        return await fetch(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/showstatus`, {
            method: 'GET',
            headers: apiHeader
        }).then(response => response.json())
            .then(statusJson => {
                const currentUserServerStatus = statusJson.data.filter(status => status.display_name === user)[0] ? statusJson.data.filter(status => status.display_name === user)[0].status_name : "Log Out"
                const currentServerStatusId = statusList.filter(slf => slf.status_name === currentUserServerStatus)[0].id

                // const currentUserServerStatus = "Training"
                // const currentServerStatusId = "5"

                if (currentUserServerStatus !== currentStatus) {
                    setCurrentStatus(currentUserServerStatus)
                    closeAuxLog().then(() => {
                            getAuxLog()
                            .then(() => getUserMeta())
                            .then((json) => updateUserMeta(currentUserServerStatus, currentServerStatusId, json))
                    })
                }
            })
    }

    const getUser = async () => {
        const fetchedUser = await fetch("https://tempeproserve.com/tatl/api/wp-json/tracking/v1/userinfo", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        })

        const jsonUser = await fetchedUser.json()
        setUser(jsonUser.display_name)
        setUserId(jsonUser.id)
    }

    async function openAuxLog(statusId) {
        return await requestAPI(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/openaux?aux-number=${statusId}`)
            .then(json => {
                let returnedLogItem = json
                return returnedLogItem
            })
        // May not need to return anything for this, just run the function
    }

    async function closeAuxLog() {
        return await fetch(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/updateaux?id=${latestAuxLog ? latestAuxLog.id : 0}`, {
            method: 'GET',
            headers: apiHeader
        }).catch(() => true)
    }

    function getAuxLog() {
        return fetch(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/read-aux-log?user=${window.userId}`, {
            method: 'GET',
            headers: apiHeader
        }).then(response => response.json())
            .then(auxlogJson => {
                setUserAuxLog(auxlogJson.data) //Correctly returns all aux logs by userId
                setLatestAuxLog(auxlogJson.data[auxlogJson.data.length - 1]) //Correctly sets state of object with the latest log of user
                setButtonToggle('') //Sets class back to empty to allow people to click on the dropdown
            })
    }

    function getUserMeta() {
        return fetch(`https://tempeproserve.com/tatl/api/wp-json/wp/v2/users/${window.userId}`, {
            method: 'GET',
            headers: apiHeader
        }).then(response => response.json())
    }

    function updateUserMeta(currentAuxStatus, latestAuxLogId, { meta }) {
        console.log(meta.tatl)
        let currentMeta
        try {
            currentMeta = JSON.parse(meta.tatl)
        } catch {
            currentMeta = {status: "Inactive", id: "13"}
        }
        const newData = {
            ...currentMeta,
            status: currentAuxStatus,
            id: latestAuxLogId
        }

        return requestAPI(`https://tempeproserve.com/tatl/api/wp-json/wp/v2/users/${window.userId}`, "POST", { meta: { tatl: JSON.stringify(newData) } })
    }

    // const getInitialTimerStatus = async () => {
    //     return requestAPI(`https://tempeproserve.com/tatl/api/wp-json/tracking/v1/showstatus`).then(j => {
    //         return j.data.filter(e => e.display_name === user)[0].elapsed
    //     })
    // }
  
    if(currentStatus !== "Log Out"){
        window.onbeforeunload = () => {return requestAPI("https://tempeproserve.com/tatl/api/wp-json/tracking/v1/update-latest-aux?aux-number=12")}
    } else {
        window.onbeforeunload = null
    }

    return (
        <nav className="navbar navbar-expand-lg navbar-dark">
            <a className="navbar-brand"><h2>ChronoCAT</h2></a>
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse text-right" id="navbarSupportedContent">
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a className="nav-link" href="#">Feedback<span className="sr-only">(current)</span></a>
                    </li>
                </ul>
            </div> */}
            <div className="ml-auto row">
                <div className="d-inline my-auto">
                    <p className="my-auto">Welcome, {user}</p>
                </div>
                <StatusDropdown />
            </div>
        </nav>
    )
}

export default Navigation