import React from "react"
// import React, { useContext } from "react"
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";
// import Home from "./Home"
import HomeTab from './HomeTab'
// import StatusLog from '../StatusLog/StatusLog'
// import PastActivity from '../PastActivity/PastActivity'
import Stats from '../Stats/Stats'//

function MainTabs() {
  return (
    <Router>
      <div>
        <nav>
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <NavLink exact className="nav-link" activeClassName="active" to="/">Home</NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/status-log/">Status Log</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/past-activity/">Past Activity</NavLink>
            </li> */}
            {<li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/stats/">Stats</NavLink>
            </li>}
          </ul>
        </nav>
        <div className="card">
          <div className="card-body">
            <Route exact path="/" component={HomeTab} />
            {/* <Route path="/status-log/" component={StatusLog} />
            <Route path="/past-activity/" component={PastActivity} /> */}
            <Route path="/stats/" component={Stats} />
          </div>
        </div>
      </div>
    </Router>

  )
}

export default MainTabs

