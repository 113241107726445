import React from "react"

function MainInvalid() {
    return (
        <div className="container card mt-4">
            <div className="card-body text-center">
                <h2>Invalid User Credentials</h2>
                <p className="h6">Your user credentials are incorrect. Please make sure you have your username and password correct in your TamperMonkey script.</p>
            </div>
        </div>
    )
}

export default MainInvalid