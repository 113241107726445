import React, { useState, useContext, useEffect } from "react";
import AnyChart from "anychart-react"
import DataTable from 'react-data-table-component';

import { arrayUnique } from "../../../functions"
import { StatusContext } from '../../Context/StatusContext'


const Conformance = ({data, title}) => {
    const {admins} = useContext(StatusContext)

    const [displayed, setDisplayed] = useState([])
    const [excluded, setExcluded] = useState([])
    const [conformance, setConformance] = useState(0)

    const sampleData = [["Allie Bannerman", 30], ["Brittany Weeks", 20], ["Celina Gonzales", 30],]

    useEffect(() => {
        const people = arrayUnique(data.map((e)=>e.display_name)).filter(e=>!admins.includes(e.display_name))
        const numberOfDays = arrayUnique(data.map((e)=>e.start_time.split(" ")[0])).length
        const filteredPeople = people.map(person=> {
            const personsData = data.filter(d=>d.display_name === person && d.elapsed)
            const elapsed = personsData.reduce((total,each)=>total + each.elapsed * 1, 0)
            const thierConformance = elapsed / numberOfDays / 450 * 100 
            window.test = personsData
            return [person, thierConformance]
        })
        setDisplayed(filteredPeople)
        window.test = displayed
        setExcluded(filteredPeople.filter(e=>e[1]<50 || e[1]>150))
        const realisticStats = filteredPeople.filter(e=>e[1]>50 && e[1]<150)
        setConformance(Math.round(realisticStats.reduce((total, numb)=>{
            return total + numb[1]
        },0)/realisticStats.length))
        let colorBorder = () => {
        filteredPeople.forEach((e,i)=>{
            if (document.querySelectorAll("g g g")[1] && document.querySelectorAll("g g g")[1].children[i]) {
            if (e[1] > 90 && e[1] < 150) {
                document.querySelectorAll("g g g")[1].children[i].style.fill = "#9f71d4"
                document.querySelectorAll("g g g")[1].children[i].style.stroke = "#9f71d4"
            } else {
                document.querySelectorAll("g g g")[1].children[i].style.fill = "#666"
                document.querySelectorAll("g g g")[1].children[i].style.stroke = "#666"
            }
        }
        })
        }
        setTimeout(() => colorBorder(),0)
    }, [data])

    return (<div>
        <p>Conformance: {conformance}%{!!excluded.length && 
        <span> (Excluding {excluded.map((e,i)=> <span key={`person-${i}`}>{e[0]}{i !== excluded.length -1 && " & "}</span>)})</span>
    }</p>
        <AnyChart
        id="bargraph-aux-lts"
        width={document.querySelector("div.card").offsetWidth -100}
        height={400}
        type="column"
        data={displayed || sampleData}
        title={title}
        />
        <DataTable
            pagination={true}
            paginationPerPage={100}
            title={<div>
              {`${displayed.length} Results`}
            </div>}
            columns={["User","Conformance"].map(e => {
                return {
                  name: e,
                  selector: e,
                  sortable: true
                }
            })}
            data={displayed.map(e=>{
                const x = {}
                x.User = e[0]
                x.Conformance = `${Math.round(e[1] * 100)/100}%`
                return x
            })}
          />
        </div>)
}
export default Conformance