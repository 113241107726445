import React, { useState, useEffect } from "react";
import AnyChart from "anychart-react"

import { arrayUnique } from "../../../functions"

const AuxLogTimeSpent = ({data, title}) => {

    const [displayed, setDisplayed] = useState([])
    const [conformance, setConformance] = useState(0)

    useEffect(() => {
        const allStatusNamesAndTimes = data.map(e=>[e.status_name, e.time_diff])
        const allStatusNames = arrayUnique(allStatusNamesAndTimes.map(e=>e[0]))
        const chartData = allStatusNames.map((auxType)=> [auxType, allStatusNamesAndTimes.filter(e=>e[0]===auxType).map((e)=>e[1]).reduce((a,b)=>Number(a)+Number(b))]).filter(e=>e[0].toLowerCase() !== "log out")
        setDisplayed(chartData)
        const onlyWorkingStatuses = chartData.filter(e=> e[0] !== "Lunch" && e[0]!=="Break" && e[0]!=="Log Out")
        const totalWorkingTime = onlyWorkingStatuses.map((e)=>e[1]).reduce((a,b)=>Number(a)+Number(b))
        const numberOfAgents = arrayUnique(data.map(e=> e.display_name)).length
        setConformance(Math.floor(totalWorkingTime / (450*numberOfAgents) * 100))
    }, [data])

    return (<div>
        <p>Conformance: {conformance}%</p>
        <AnyChart
        id="bargraph-aux-lts"
        width={400}
        height={400}
        type="pie"
        data={displayed}
        title={title}
        />
        </div>)
}
export default AuxLogTimeSpent