import React from "react"

function ActivityLog() {
    return (
        <div className="">
            <h2>Activity Log</h2>
            <p>Coming soon!</p>
        </div>
    )
}

export default ActivityLog