import React, { useContext } from 'react'
import StatusDropdown from '../General/StatusDropdown'
import ActivityLog from '../General/ActivityLog'
import StatusTimer from './StatusTimer'
import { StatusContext } from '../Context/StatusContext'
import ConformanceBar from './ConformanceBar'
import StatusChart from './StatusChart'

function HomeTab() {
        const { userData, userAuxLog } = useContext(StatusContext)


        return (
                <div className="container">
                        <div className="row">
                                <div className="col-md-6">
                                        <div className="row">
                                                <div className="col-md-3">
                                                        <StatusDropdown />
                                                </div>
                                                <div className="col-md-9">
                                                        {userData != null ? <StatusTimer /> : <p>Loading</p>}
                                                </div>
                                        </div>
                                        <div className="row">
                                                <div className="col-md-12">
                                                        {/* <ConformanceBar/> */}
                                                </div>
                                        </div>
                                </div>

                                <div className="col-md-6">
                                       <StatusChart/>
                                </div>
                        </div>
                </div>
        )
}

export default HomeTab 