import React, { useState, useEffect, useContext } from "react";
import {  Dropdown} from "react-bootstrap";
import "react-daterange-picker/dist/css/react-calendar.css";
import Modal from 'react-modal';
import DropdownItem from "react-bootstrap/DropdownItem";


import { StatusContext } from "../Context/StatusContext";
import NewView from "./NewView";
import {updateUserMeta, getUserMeta} from "../../functions"

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)',
      padding               : "10px",
    }
  }

  Modal.setAppElement('#root')

function ViewDropdown({ data }) {
const {view, setView, views, setViews, allUsers} = useContext(StatusContext)

useEffect(() => {
  setTimeout(() =>{
    const setAdminviews = async () => {
      const userMeta = await getUserMeta(window.userId)
      const r = userMeta.views ? JSON.parse(userMeta.views) : null
      if (r) {setViews(r)}
      }
      setAdminviews()
  }, 900)
}, [])

const [modalIsOpen, setModalIsOpen] = useState(false)
const [selectedOptions, setSelectedOptions] = useState([])
const [newViewName, setNewViewName] = useState(``)
const [editMode, setEditMode] = useState('')

const openModal = () => {
  setModalIsOpen(true)
}

const afterOpenModal = () => {
  // references are now sync'd and can be accessed.
  // subtitle.style.color = '#f00';
}

const closeModal = () => {
  setModalIsOpen(false)
  setEditMode(false)
  setNewViewName("")
  setSelectedOptions([])
}


const handleCreateView = async () => {
  if (selectedOptions.length && newViewName.length) {
    const newViews = [...views.filter(e=> e.viewName !== newViewName), {viewName: newViewName, users: selectedOptions}]
    setViews(newViews)
    console.log(await updateUserMeta(window.userId,"views", JSON.stringify(newViews)))
    closeModal()
  } else if (selectedOptions.length && !newViewName.length){
    alert("Please name your view.")
  } else if (!selectedOptions.length && newViewName.length){
    alert("Please select some people.")
  } else {
    alert("Please select some people and name your view.")
  }
  }

const handleNewViewNameChange = (e) => {
  window.test = e
  setNewViewName(e.target.value)
}

const handleRightClick = (e) => {
  e.preventDefault()
  const SelectedView = views.find(ea=> ea.viewName === e.target.innerHTML)
  setSelectedOptions(SelectedView.users)
  setEditMode(true)
  setNewViewName(SelectedView.viewName)
  openModal()
  console.log(SelectedView)
}

const handleSaveAs = () => {
  setEditMode(false)
  setTimeout(() =>document.getElementById('viewName').focus(), 300)
  setNewViewName(`${newViewName}-copy`)
}

const handleDelete = async () => {
  const isSure = window.confirm(`Are you sure that you want to delete the view "${newViewName}?"`)
  if (isSure) {
    const newViews = [...views.filter(e=> e.viewName !== newViewName)]
    setViews(newViews)
    console.log(await updateUserMeta(window.userId,"views", JSON.stringify(newViews)))
    closeModal()
  }
}

  return (
    <div>
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div>
      <h2 className="fl">{!editMode ? 'Add a New View' : 'Edit View'}</h2> <div className="fr closeButton" onClick={closeModal}>X</div>
      </div>
      <input type="text" id="viewName" disabled={editMode && true} placeholder="View Name" className="form-control" onChange={handleNewViewNameChange} value={newViewName}></input>
      <div class="section">
      {editMode && <div className="fr pointer text-button danger" onClick={handleDelete}>Delete</div>}
      {editMode && <div className="pointer text-button" onClick={handleSaveAs}>Clone</div>}
      </div>
<NewView allUsers={allUsers} selectedOptions={selectedOptions} setSelectedOptions={setSelectedOptions} editMode={editMode} />
      <button className="btn btn-primary" onClick={handleCreateView}>Create View</button>
    </Modal>
    <Dropdown>
        <Dropdown.Toggle variant="success" id="dropdown-basic">
            {view}
        </Dropdown.Toggle>
        <Dropdown.Menu>
                  <Dropdown.Item onClick={openModal}>
                      + Add New View
                  </Dropdown.Item>
                  {views.map((e,i)=> {
                      return (
                          <DropdownItem onClick={()=>setView(e.viewName)} onContextMenu={handleRightClick} key={`dd${i}`}>
                          {e.viewName}
                      </DropdownItem>
                      )
                    })}
                </Dropdown.Menu>
    </Dropdown>
                    </div>
  );
}

export default ViewDropdown;