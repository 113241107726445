import React from 'react'
import MainTabs from './MainTabs'

function Main() {
  return (
    <div className="container mt-4">
      <MainTabs />
    </div>
  )
}

export default Main