import React, { useState, createContext } from 'react'

export const StatusContext = createContext()

export const StatusProvider = props => {
    const token = localStorage.getItem("tattl-jwt")
    const [currentStatus, setCurrentStatus] = useState("Select Status")
    const [statusList, setStatusList] = useState([])
    const [isLoaded, setIsLoaded] = useState(false)
    const [userData, setUserData] = useState(null)
    const [userAuxLog, setUserAuxLog] = useState('')
    const [auxLogLoaded, setAuxLogLoaded] = useState(false)
    const [latestAuxLog, setLatestAuxLog] = useState('')
    const [latestAuxLogLoaded, setLatestAuxLogLoaded] = useState('')
    const [loggoutButton, setLoggoutButton] = useState('')
    const [userMetaLog, setUserMetaLog] = useState('')
    const [buttonToggle, setButtonToggle] = useState('')
    const [isAdmin, setAdmin] = useState(false)
    const [admins, setAdmins] = useState()
    const [hideAdmin, setHideAdmin] = useState(true)
    const [view, setView] = useState('Select View')
    const [views, setViews] = useState([])
    const [allUsers, setAllUsers] = useState([])

    return(
        <StatusContext.Provider value={{token, currentStatus, setCurrentStatus, statusList, setStatusList, isLoaded, setIsLoaded, userAuxLog, setUserAuxLog, latestAuxLog, setLatestAuxLog, auxLogLoaded, setAuxLogLoaded, latestAuxLogLoaded, setLatestAuxLogLoaded, loggoutButton, setLoggoutButton, buttonToggle, setButtonToggle, userData, setUserData, isAdmin, setAdmin, userMetaLog, setUserMetaLog, admins, setAdmins, hideAdmin, setHideAdmin, view, setView, views, setViews, allUsers, setAllUsers}}>
            {props.children}
        </StatusContext.Provider>
    )
}