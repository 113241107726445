import React, { useState, useEffect } from 'react'
import Main from './Main'
import MainInvalid from './MainInvalid'


function Home() {

    const [tokenready, setTokenready] = useState(!1)
    const getToken = async () => {
        const response = await fetch("https://tempeproserve.com/tatl/api/wp-json/jwt-auth/v1/token", {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({username: window.username, password: window.password}),
            "method": "POST",
        })

        const json = await response.json()
        localStorage.setItem("tattl-jwt", json.token)
        localStorage.setItem("tattl-jwt-time", (new Date()).getDate())
        // console.log(`Couldn't authenticate requested a new token and recieved ${token ? token : "an error"}`)
        setTokenready(json.token)
        return json.token ? json.token : !1
    }
    useEffect(() => {
        
    }, [])

    if (getToken()) {
        return (
         <Main id={tokenready} />
        )
    } else /*if (!token)*/ {
        return (<MainInvalid />)
    } 
    // else {
    //     return (<p>Something has gone wrong. Please bring this to ProServe's attention.</p>)
    // }
}

export default Home 