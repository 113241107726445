import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Row,
  Col,
  Dropdown,
  Form,
  Toast,
  FormCheck
} from "react-bootstrap";
import DateRangePicker from "react-daterange-picker"; // https://www.npmjs.com/package/react-daterange-picker
import "react-daterange-picker/dist/css/react-calendar.css";
import moment from "moment";

import { StatusContext } from "../Context/StatusContext";
import { downloadAsCSV, requestAPI } from "../../functions.js";
import ViewDropdown from "./ViewDropdown";

function ReportDropdown({ data }) {
  const [downloadNothing, setDN] = useState(null);
  const [endpoint, setEndpoint] = useState(null);
  const [rangeSelection, setRangeSelection] = useState(false);

  const {hideAdmin, setHideAdmin, isAdmin} = useContext(StatusContext)

  useEffect(() => {
    handleSetReport(endpoint, data.dropdown);
  }, [data.range]);

  useEffect(() => {
    data.setRange(moment())
  },[])

  const formatDate = inputDate => {
    return inputDate
      .toISOString()
      .replace("T", " ")
      .split(" ")[0];
  };

  const handleSetReport = (end, choice) => {
    if (!choice) {
      return !1;
    }
    data.setDropdown(choice);
    const formattedChoice = choice
      .toLowerCase()
      .split("")
      .map(each => each.match(/\w/))
      .join("");
    //see if we're using a date range, single date, else use default
    let { date1, date2 } = formatDate(new Date());
    if (data.range && data.range.start) {
      date1 = formatDate(data.range.start["_d"]);
      date2 = formatDate(data.range.end["_d"]);
    } else if (data.range["_d"]) {
      date1 = date2 = formatDate(data.range["_d"]);
    }
    end += `?date1=${date1}&date2=${date2}`;
    const fileName = `${formattedChoice}-${formatDate(new Date())}`;
    data.setReport([data.report, fileName]);
    requestAPI(
      `https://tempeproserve.com/tatl/api/wp-json/tracking/v1/${end}`
    ).then(j => {
      data.setDD(j.data);
      data.setFileName(fileName);
    });
  };

  const handleToggle = () => {
    setRangeSelection(!rangeSelection);
  };

  return (
    <div>
      <Row>
        <Col>
          <Form.Label>Filter</Form.Label>
          <Form.Control
            type="text"
            placeholder="ie. billy"
            className="my-2"
            onChange={e => data.setFilter(e.target.value)}
          />
          <Row>
            <Col>
              <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                  {!data.fileName ? `Select Report` : data.dropdown}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={e => {
                      setEndpoint("get-conformance-records");
                      handleSetReport(
                        "get-conformance-records",
                        e.target.innerHTML
                      );
                    }}
                  >
                    Conformance Records
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => {
                      setEndpoint("aux-from-range");
                      handleSetReport("aux-from-range", e.target.innerHTML);
                    }}
                  >
                    Aux Range
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => {
                      setEndpoint("read-ticket-log");
                      handleSetReport("read-ticket-log", e.target.innerHTML);
                    }}
                  >
                    Ticket Log
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={e => {
                      setEndpoint("showstatus");
                      handleSetReport("showstatus", e.target.innerHTML);
                    }}
                  >
                    Current Statuses
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            {isAdmin && 
            <Col>
            <ViewDropdown />
            </Col>
            }
            <Col>
              <Button
                variant="outline-primary"
                onClick={() => {
                  if (data.report) {
                    downloadAsCSV(data.filteredData, data.fileName);
                  } else {
                    setDN(!0);
                    setTimeout(() => setDN(!1), 4000);
                  }
                }}
              >
                Download
              </Button>
              {downloadNothing && (
                <Toast>
                  <Toast.Header>
                    <img
                      src="holder.js/20x20?text=%20"
                      className="rounded mr-2"
                      alt=""
                    />
                    <strong className="mr-auto">Error</strong>
                    <small>just now.</small>
                  </Toast.Header>
                  <Toast.Body>Please select a report first.</Toast.Body>
                </Toast>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <DateRangePicker
            onSelect={dates => {
              data.setRange(dates);
              // handleSetReport(endpoint, datadropdown)
            }}
            value={data.range}
            selectionType={rangeSelection ? "range" : "single"}
          />
          <Row>
            <Col>
          <FormCheck className="toggle-switch" custom={true} type="switch">
            <FormCheck.Input checked={rangeSelection} onClick={handleToggle} />
            <FormCheck.Label onClick={handleToggle}>
              Select by range
            </FormCheck.Label>
          </FormCheck>
          </Col>
          <Col>
          {isAdmin && <FormCheck className="toggle-switch" custom={true} type="switch">
            <FormCheck.Input checked={hideAdmin} onClick={() =>setHideAdmin(!hideAdmin)} />
            <FormCheck.Label onClick={() =>setHideAdmin(!hideAdmin)}>
              Hide Admins
            </FormCheck.Label>
          </FormCheck> }
          </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default ReportDropdown;
