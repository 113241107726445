import React from "react";

import TicketLogTimeSpent from "./charts/TicketLogTimeSpent"
import AuxLogTimeSpent from "./charts/AuxLogTimeSpent"
import Conformance from "./charts/Conformance"

const ChartRenderer = ({data, title}) => {
    return (
        <div>
        {title === "Ticket Log" && <TicketLogTimeSpent data={data} title={title} />}
        {title === "Aux Range" && <AuxLogTimeSpent data={data} title={title} />}
        {title === "Conformance Records" && <Conformance data={data} title={title} />}
        </div>
    )
}
export default ChartRenderer